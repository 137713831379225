import dynamic from 'next/dynamic';

export type ComponentName = keyof typeof LazyContainers;

export const LazyContainers = {
    ArticlePage: dynamic(() => import('./ArticlePage')),
    BasePage: dynamic(() => import('./BasePage')),
    HomePage: dynamic(() => import('./HomePage')),
    NotFoundPage: dynamic(() => import('./NotFoundPage')),
    PureHtmlPage: dynamic(() => import('./PureHtmlPage')),
    PrivacyPolicyPage: dynamic(() => import('./PrivacyPolicyPage')),
    TalentProfilePage: dynamic(() => import('./TalentProfilePage')),
    RegularPage: dynamic(() => import('./RegularPage')),
    LegalPage: dynamic(() => import('./LegalPage')),
    ProfileVacancyPage: dynamic(() => import('./ProfileVacancyPage')),
    ReferralFormsPage: dynamic(() => import('./ReferralPage')),
};
